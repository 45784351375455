/* eslint-disable react/display-name */
import React from 'react';
import DynamicImage from './DynamicImage'
import tw from "twin.macro";

// const Text = tw.h2`
//     text-6xl
//     font-serif
//     font-medium
//     py-2
//     mx-2
//     leading-none
//     align-middle
// `

const ImgBox = tw.div`
    w-1/2
    shadow-lg
`

export default () => {
    return (
    <div className="flex  flex-wrap justify-center items-center w-full my-16">
        <ImgBox>
            <DynamicImage 
                src={"3.jpg"}
            />
        </ImgBox>  
        <ImgBox>

            <DynamicImage 
                src={"7.jpg"}
            />
        </ImgBox>  
        <ImgBox>

            <DynamicImage 
                src={"4.jpg"}
            />
        </ImgBox>  
        <ImgBox>

            <DynamicImage 
                src={"6.jpg"}
            />
        </ImgBox>  
    </div>
    )
}


