import React from 'react';
import DynamicImage from './DynamicImage'
import tw from "twin.macro";



const Text = tw.h2`
    text-6xl
    font-serif
    font-medium
    py-2
    mx-2
    leading-none
    align-middle
`

const Hero = () => {
    return (
    <div className="flex justify-center items-center w-full">
        <div className="w-full">
            <DynamicImage 
                src={"2.jpg"}
            />
        </div>  
        <div className="w-full">
            <div className="ml-5">
            <Text>
                Save Time
            </Text>
            <Text className="italic">
                Eliminate Waste
            </Text>
            <Text>
                No Mess
            </Text>
            </div>
        </div>
    </div>
    )
}

export default Hero;

