/* eslint-disable react/display-name */
import React from 'react';
import DynamicImage from './DynamicImage'
import tw from "twin.macro";
import { Link } from "gatsby";
import { loadStripe } from "@stripe/stripe-js"


// const Text = tw.h2`
//     text-6xl
//     font-serif
//     font-medium
//     py-2
//     mx-2
//     leading-none
//     align-middle
// `
const Title = tw.h1`
    text-center
    text-2xl
    my-2
`
const Stock = tw.p`
    text-center
    text-xs
    my-5
`

const ImgBox = tw.div`
    w-1/3
    p-1
    text-center
    justify-center
    font-thin
`
const ButtonStyle = tw.span`
    border-2
    border-black
    border-solid
    px-5
    py-1
    text-sm
    bg-black
    text-white
    hover:bg-white
    hover:text-black
`

//const stripePromise = loadStripe(process.env.GATSBY_STRIPE_LIVE_PUBLIC_KEY)
const stripePromise = loadStripe("pk_test_BmDbpvEgjVd6lHUInbpj7NcB00H6fXeaQi")

const redirectToCheckout  = async (event, SKU) => {
    event.preventDefault()
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: SKU, quantity: 1 }],
      mode: "payment",
      successUrl: 'https://paste.zira.dev/success',
      cancelUrl: 'https://paste.zira.dev/cancel',
      shippingAddressCollection: {
        allowedCountries: ["US"]
      }
    })
    if (error) {
      console.warn("Error:", error)
    }
  }


export default () => {
    return (
    <div className="flex  flex-wrap justify-center items-center w-full my-5">
        <ImgBox>
            <DynamicImage
                src={"blue.png"}
            />
            <Title>Navy</Title>
            <Title>$30</Title>
            <Link onClick={(e) => {
                // redirectToCheckout(e, process.env.GATSBY_PASTE_DISPENSER_NAVY)
                redirectToCheckout(e, "price_1GyR8QESvRZvpDszcjrxcP2h")

                }
            }>
                <ButtonStyle>
                    BUY NOW
                </ButtonStyle>
            </Link>
            <Stock>IN STOCK</Stock>
        </ImgBox>
        <ImgBox>
            <DynamicImage
                src={"green.png"}
            />
            <Title>Seafoam</Title>
            <Title>$30</Title>
            <Link onClick={(e) => {
                redirectToCheckout(e, process.env.GATSBY_PASTE_DISPENSER_SEAFOAM)
                }
            }>
                <ButtonStyle>
                    BUY NOW
                </ButtonStyle>
            </Link>
            <Stock>LIMITED STOCK</Stock>
        </ImgBox>
        <ImgBox>
            <DynamicImage
                src={"white.png"}
            />
            <Title>Pearl</Title>
            <Title>$30</Title>
            <Link onClick={(e) => {
                redirectToCheckout(e, process.env.GATSBY_PASTE_DISPENSER_PEARL)
                }
            }>
                <ButtonStyle>
                    BUY NOW
                </ButtonStyle>
            </Link>
            <Stock>IN STOCK</Stock>
        </ImgBox>
    </div>
    )
}


