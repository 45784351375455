import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero";
import ImageRow from "../components/ImageRow";
import CopyRow from "../components/CopyRow"
import ProductRow from "../components/ProductRow"
import Policy from "../components/Policy"


function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`paste`, `toothpaste`, `dispenser`]}
      />
      <Hero />
      <CopyRow />
      <ProductRow />
      <ImageRow />
      <Policy />
    </Layout>
  );
}

export default IndexPage;
