/* eslint-disable react/display-name */
import React from 'react';

export default () => {
    return (
    <div className="flex flex-col  justify-center items-center w-full my-32">
        <div>
            <h1 className="text-5xl font-thin py-4">
            RETURNS &
            SHIPPING
            </h1>
        </div>
        <div>
            <p className=" text-sm  text-left">
            <div className="flex-row m-6">
                <div className="underline font-bold my-4">RETURNS & EXCHANGES</div>
                    YOU ARE WELCOME TO RETURN ALL UNALTERED ITEMS WITHIN 30 DAYS OF THE ORIGINAL PURCHASE DATE. IF YOU CHOOSE TO RETURN AN ITEM, $10 WILL BE WITHHELD FROM YOUR REFUND TO COVER SHIPPING AND RESTOCKING.
                    IF THE ITEM IS LISTED AS FINAL SALE, IT IS NOT ELIGIBLE FOR A RETURN. INTERNATIONAL ORDERS SHIPPED OUTSIDE OF THE US CANNOT BE RETURNED.
            </div>
            <div className="flex-row m-6">
                <div className="underline font-bold my-4">SHIPPING</div>
                ALLOW 1-7 BUSINESS DAYS FOR YOUR ORDER TO PROCESS. YOUR ORDER WILL TAKE 2-7 BUSINESS DAYS TO GET TO YOU IF YOU ORDER FROM THE US, AND CAN TAKE LONGER DEPENDING ON WHERE YOU ARE IN THE WORLD. YOU WILL RECEIVE A TRACKING NUMBER ALONG WITH A SHIPPING CONFIRMATION EMAIL ONCE ORDERS HAVE BEEN PROCESSED AND SHIPPED. PLEASE NOTE THAT ZIRA LLC IS NOT LIABLE FOR LOST OR STOLEN PACKAGES OR ANY IMPORT TAXES IF APPLICABLE.

                WE PROVIDE FREE SHIPPING ON ALL US DOMESTIC ORDERS AND SHIP WORLD WIDE AT A FLAT RATE OF $50.
            </div>
            <div className="flex-row m-6">
                <div className="underline font-bold my-4">
                QUESTIONS?
                </div>
                CONTACT INFO@PASTE.ZIRA.DEV
            </div>
            </p>
        </div>
    </div>
    )
}


