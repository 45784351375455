/* eslint-disable react/display-name */
import React from 'react';

export default () => {
    return (
    <div className="flex flex-col  justify-center items-center w-full my-32">
        <div>
            <h1 className="text-5xl font-thin py-4">
            Designed with <span className="italic underline">you</span> in Mind.
            </h1>
        </div>
        <div>
            <p className="leading-loose text-xl  font-thin tracking-wide text-center">
                We’ve combined years of design experience and feedback from our previous creations to build a premium tool for you. Smooth surfaces with clean lines. Providing a better, faster experience tailored to your needs. We've focused on what you’ve told us is important to you.
            </p>
        </div>
    </div>
    )
}


